import { Environment } from './environment.model';

export const environment: Environment = {
  productionMode: true,
  googleTagManagerId: 'GTM-MW8KS58',
  prismicEndpoint: 'https://sunrise-lebara.cdn.prismic.io/api/v2',
  brand: 'lebara',
  oldEshopLink: 'https://eshopengine.yol.ch',
  // Don't need to redirect off the main domain name on production as cloudflare will handle the reverse proxying
  legacyRedirect: '',
  internalUrls: [
    /^(\/(en|de|fr|it))?\/?$/,
    /^(\/(en|de|fr|it))?\/5g(\/.+)?/,
    /^(\/(en|de|fr|it))?\/2g(\/.+)?/,
    /^(\/(en|de|fr|it))?\/3g(\/.+)?/,
    /^(\/(en|de|fr|it))?\/mobile-products(\/.+)?/,
    /^(\/(en|de|fr|it))?\/not-found(\/.+)?/,
    /^(\/(en|de|fr|it))?\/promo\/?$/,
    /^(\/(en|de|fr|it))?\/terms\/?$/,
    /^(\/(en|de|fr|it))?\/home(\/.+)?/,
    /^(\/(en|de|fr|it))?\/top-deal(\/.+)?/,
    /^(\/(en|de|fr|it))?\/best-offer\/?$/,
    /^(\/(en|de|fr|it))?\/awards\/?$/,
    /^(\/(en|de|fr|it))?\/data-deal(\/.+)?/,
    /^(\/(en|de|fr|it))?\/lebara_prepaid\/?$/,
    /^(\/(en|de|fr|it))?\/mobile-products(\/.+)?/,
    /^(\/(en|de|fr|it))?\/contest\/?$/,
    /^(\/(en|de|fr|it))?\/prepaid\/?$/,
    /^(\/(en|de|fr|it))?\/contest-more\/?$/,
    /^(\/(en|de|fr|it))?\/contest-offer\/?$/,
    /^(\/(en|de|fr|it))?\/esim(\/.+)?/,
    /^(\/(en|de|fr|it))?\/media(\/.+)?/,
    /^(\/(en|de|fr|it))?\/resubscribe(\/.+)?/,
    /^(\/(en|de|fr|it))?\/cookie-policy(\/.+)?/,
    /^(\/(en|de|fr|it))?\/lebara(\/.+)?/,
    /^(\/(en|de|fr|it))?\/app\/?$/,
    /^(\/(en|de|fr|it))?\/shop(\/.+)?/,
    /^(\/(en|de|fr|it))?\/world\/?$/,
    /^(\/(en|de|fr|it))?\/data-protection\/?$/,
    /^(\/(en|de|fr|it))?\/infla[a-z]{1,2}io[a-z]{1,2}\/?$/,
    /^(\/(en|de|fr|it))?\/blackfriday(\/.+)?/,
    /^(\/(en|de|fr|it))?\/cybermonday(\/.+)?/,
    /^(\/(en|de|fr|it))?\/data(\/.+)?/,
    /^(\/(en|de|fr|it))?\/auth(\/.+)?/,
    /^(\/(en|de|fr|it))?\/phishing\/?$/,
    /^(\/(en|de|fr|it))?\/mms\/?$/,
    /^(\/(en|de|fr|it))?\/neptun(\/.+)?/,
    /^(\/(en|de|fr|it))?\/roamingtips\/?$/,
    /^(\/(en|de|fr|it))?\/partner(\/.+)?/,
    /^(\/(en|de|fr|it))?\/credit-rejection-limit\/?$/,
    /^(\/(en|de|fr|it))?\/credit-rejection-hard-stop\/?$/,
    /^(\/(en|de|fr|it))?\/credit-rejection-too-many-subs\/?$/,
    /^(\/(en|de|fr|it))?\/welcome(\/.+)?/,
    /^(\/(en|de|fr|it))?\/volte(\/.+)?/,
    /^(\/(en|de|fr|it))?\/costprotection(\/.+)?/,
    /^(\/(en|de|fr|it))?\/summer(\/.+)?/,
    /^(\/(en|de|fr|it))?\/oneplus(\/.+)?/,
    /^(\/(en|de|fr|it))?\/secret-offer(\/.+)?/,
    /^(\/(en|de|fr|it))?\/roaming-info(\/.+)?/,
    /^(\/(en|de|fr|it))?\/smartwatch(\/.+)?/,
    /^(\/(en|de|fr|it))?\/smartwatch-solo(\/.+)?/,
    /^(\/(en|de|fr|it))?\/price-info(\/.+)?/,
  ],
  fallbackLang: 'de',
  ratePlansPath: '/resources/rateplans.json',
  newMicroServiceEndpoint: 'https://ms.lebara.ch',
  checkoutUrl: 'https://checkout.lebara.ch',
  allowedMsEnvs: [],
  eksMSEnvs: ['integration'],
  defaultUrlsEnvironment: '',
  yalloFreeTvLoginUrl: 'na',
  yalloTvUrl: 'na',
  distributionIds: ['E32RHNTX46ENJM', 'E8UW5WJBYEOBD', 'ELAGE54J1U2MJ'],
  featureFlagClientKey: 'sdk-1AyWzT53SObto3SV',
  selfcareUrl: 'https://my.lebara.ch',
  sprinklerAppId: '66c60bb29bfd097343b32b8c_app_444',
};
